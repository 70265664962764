<template>
  <v-container>
    <v-card class="table-card">
      <v-card-title>
        <v-row class="d-flex justify-space-between align-center mb-2 px-2">
          <div class="d-flex">
            <v-avatar size="100" class="elevation-6 ms-1" :color="color">
              <v-icon dark size="64">
                $sensor
              </v-icon>
            </v-avatar>
            <div class="table-card-title ms-5 text-h2 d-flex">
              <span class="d-none d-sm-block">{{ $t('device') }}</span>
              <strong v-if="device" class="ms-sm-2">&nbsp;-&nbsp;
                <span class="purple--text">{{ device.identifier }}</span>
              </strong>
            </div>
          </div>
          <div v-if="device && battery">
            <span :color="battery.color">
              <v-icon :color="battery.color" v-text="battery.icon" />
              <strong>
                {{ $t(battery.text) }}
              </strong>
            </span>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text v-if="device">
        <router-view />
      </v-card-text>
      <v-card-text v-else>
        <div class="pa-5 d-flex flex-row justify-center">
          <v-progress-circular
            :size="180"
            :width="7"
            color="purple"
            indeterminate
          >
            {{ $phraser.set('loading device') }}...
          </v-progress-circular>
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-tabs
        v-model="tab"
        background-color="primary"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider />

        <v-tab :to="{ name: 'DeviceDetails' }">
          {{ $t('details') }}
          <v-icon size="30">
            mdi-router-wireless
          </v-icon>
        </v-tab>

        <v-tab :to="{ name: 'DeviceSettings' }">
          הגדרות
          <v-icon size="30">
            mdi-router-wireless-settings
          </v-icon>
        </v-tab>

        <v-tab :to="{ name: 'DeviceLogs' }">
          לוגים
          <v-icon size="30">
            mdi-timeline-text-outline
          </v-icon>
        </v-tab>

        <v-tab :to="{ name: 'DeviceUsers' }">
          {{ $t('users') }}
          <v-icon size="30">
            mdi-account-group
          </v-icon>
        </v-tab>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import SetBatteryMixin from '@/mixins/SetBatteryMixin'
export default {
  name: 'Device',

  components: {},
  mixins: [SetBatteryMixin],

  data: () => ({
    color: 'purple',
    tab: 0,
    battery: {},
  }),

  computed: {
    device () {
      const device = this.$store.getters['devices/getBySlug'](
        this.$route.params.slug,
      )
      return device ?? null
    },
  },

  watch: {
    device: {
      handler (device) {
        if (device.data && device.data.battery) {
          this.battery = this.setBattery(device.data.battery.value)
        }
      },
      deep: true,
    },
  },
}
</script>
